
export const HOME_ADDRESS = '/'
// export const START_ADDRESS = '/start'
export const TRUSTS_ADDRESS = '/trusts'
export const ASSETS_ADDRESS = '/assets'
export const BENEFICIARY_ADDRESS = '/beneficiaries'
export const SAFE_PARAM_ADDRESS = 'address'
export const CREATE_ADDRESS = '/create'
export const IMPORT_ADDRESS = '/import'
export const TERMS_ADDRESS = '/terms'
export const COOKIES_ADDRESS = '/cookies'
export const PRIVACY_ADDRESS = '/privacy'